import React from "react"

import logoStyles from "../../assets/styles/modules/logo_grid.module.scss"

import Link from "gatsby-link"

interface LogoProps {
  altText: string
  image: []
  link: string
}

// console.log(logoStyles)
const Logo = (props: LogoProps) => {
  const { altText, image, link } = props
  return (
    <div className={logoStyles.logoGrid__logo}>
      <a href={`${link}`} target="_blank">
        <img
          className={logoStyles.logoGrid__img}
          src={`${image}`}
          alt={altText}
        />
      </a>
    </div>
  )
}

export default Logo
