import React from "react"
import Placeholder from "../../../static/images/default_image.jpg"
import IsModernBrowser from "../_utils/is_browser_modern"

interface ListingHeroProps {
  image?: any
  isPlaceholder?: boolean
}
const Thumbnail = ({ image, isPlaceholder }: ListingHeroProps) => {
  return (
    <>
      <div className="listing-thumbnail">
        {IsModernBrowser() ? (
          !isPlaceholder && image ? (
            <>
              <picture className="listing-thumbnail__image image">
                <img src={image.url} alt={image.alt} loading="lazy" />
              </picture>
              <picture className="listing-thumbnail__image mask">
                <img
                  src={image.url}
                  alt={image.alt}
                  loading="lazy"
                  className="listing-thumbnail__image mask"
                />
              </picture>
            </>
          ) : (
            <>
              <img
                src={Placeholder}
                alt="Placeholder image"
                className="listing-thumbnail__image image"
              />
              <img
                src={Placeholder}
                alt="Placeholder image"
                className="listing-thumbnail__image mask"
              />
            </>
          )
        ) : !isPlaceholder && image ? (
          <>
            <div
              style={{
                background: `url(${image.url})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
              className="listing-thumbnail__image image"
            />
            <div
              style={{
                background: `url(${image.url})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
              className="listing-thumbnail__image mask"
            />
          </>
        ) : (
          <>
            <img
              src={Placeholder}
              alt="Placeholder image"
              className="listing-thumbnail__image image"
            />
            <img
              src={Placeholder}
              alt="Placeholder image"
              className="listing-thumbnail__image mask"
            />
          </>
        )}
      </div>
    </>
  )
}

export default Thumbnail
