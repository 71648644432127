import React from "react"

import { Link } from "gatsby"

import ReactMarkdown from "react-markdown"
import { ListingProps } from "../types"

import PrimaryCta, { themes } from "../../buttons/primary_cta"
import { Tldr } from "../../svgs"
import Thumbnail from "../../thumbnail"

import styles from "./index.module.scss"

import { theme } from "../../_utils/constants"

const StoryNewsListing = ({
  ctaLabel,
  excerpt,
  date,
  hasCta,
  slug,
  isStoryListing,
  hasImage,
  title,
  image,
  isOurProgramListing,
}: ListingProps) => {
  return (
    <div className={styles.listing}>
      {hasCta ? (
        <>
          <Link to={`${slug}`}>
            {hasImage ? (
              <Thumbnail image={image} />
            ) : (
              <Thumbnail isPlaceholder={true} />
            )}
          </Link>

          <Link to={`${slug}`}>
            <div
              className={`${styles.listing__head} ${styles.listing__row} ${
                isOurProgramListing
                  ? styles.listing__headCondense
                  : styles.listing__headExpand
              }`}
            >
              {!isOurProgramListing && (
                <div
                  className={`typography__display--exception ${
                    styles.listing__category
                  } ${
                    isStoryListing
                      ? styles.listing__categoryStory
                      : styles.listing__categoryNews
                  }`}
                >
                  {isStoryListing ? "Story" : "News"}
                </div>
              )}
              <h2 className="typography__display--4  typography__text-trans--none">
                {title}
              </h2>
              <div className={styles.listing__tldr}>
                {isOurProgramListing ? (
                  <Tldr color={theme.navy} />
                ) : (
                  <Tldr color={isStoryListing ? theme.blue : theme.green} />
                )}
              </div>
              <small
                className={`${styles.listing__date} typography__display--exception`}
              >
                {!isStoryListing && date ? (
                  date.substring(0, 10)
                ) : (
                  <span>&nbsp;</span>
                )}
              </small>
            </div>
          </Link>

          <div
            className={`${styles.listing__body} ${styles.listing__row} ${
              !hasCta ? "util__min-height--0" : ""
            }`}
          >
            <div className="typography__color--navy">
              <ReactMarkdown source={excerpt} />
            </div>
            {hasCta &&
              (isOurProgramListing ? (
                <PrimaryCta
                  label={`${ctaLabel}`}
                  theme={themes.navy}
                  to={`${slug}`}
                />
              ) : (
                <PrimaryCta
                  label="Learn More"
                  theme={themes.navy}
                  to={`${slug}`}
                />
              ))}
          </div>
        </>
      ) : (
        // </Link>
        <div>
          {hasImage ? (
            <Thumbnail image={image} />
          ) : (
            <Thumbnail isPlaceholder={true} />
          )}

          <div
            className={`${styles.listing__head} ${
              isOurProgramListing
                ? styles.listing__headCondense
                : styles.listing__headExpand
            }`}
          >
            {!isOurProgramListing && (
              <div
                className={`typography__display--exception ${
                  styles.listing__category
                } ${
                  isStoryListing
                    ? styles.listing__categoryStory
                    : styles.listing__categoryNews
                }`}
              >
                {isStoryListing ? "Story" : "News"}
              </div>
            )}
            <h2 className="typography__display--4   typography__text-trans--none">
              {title}
            </h2>
            <div className={styles.listing__tldr}>
              {isOurProgramListing ? (
                <Tldr color={theme.navy} />
              ) : (
                <Tldr color={isStoryListing ? theme.blue : theme.green} />
              )}
            </div>
            <small
              className={`${styles.listing__date} typography__display--exception`}
            >
              {!isStoryListing ? (
                typeof date !== "undefined" && date.substring(0, 10)
              ) : (
                <span>&nbsp;</span>
              )}
            </small>
          </div>

          <div
            className={`${styles.listing__body} ${
              !hasCta ? "util__min-height--0" : ""
            }`}
          >
            <div className="typography__color--navy">
              <ReactMarkdown source={excerpt} />
            </div>
            {hasCta &&
              (isOurProgramListing ? (
                <PrimaryCta
                  label={`${ctaLabel}`}
                  theme={themes.navy}
                  to={`${slug}`}
                />
              ) : (
                <PrimaryCta
                  label="Learn More"
                  theme={themes.navy}
                  to={`${slug}`}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default StoryNewsListing
