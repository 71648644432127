import React, { useEffect, useState } from "react"
import Head from "../components/global/head"

import { graphql } from "gatsby"
import HeaderBreadcrumbs from "../components/header_breadcrumbs"
import ImageTextBlock from "../components/modules/image_text_block"
import CtaBanner from "../components/modules/cta_banner"

import ContentBoundary from "../components/content/boundary"
import ModuleRenderer from "../components/modules"
import HeroIntroBanner from "../components/modules/hero_intro_banner"

import StoryNewsListing from "../components/modules/story_news_listing"

import LogoGrid from "../components/logo_grid"

const OurProgramDetailTemplate = ({ data, location, pageContext }) => {
  const {
    seoTitle,
    seoKeywords,
    seoDescription,
    slug,
    programTitle,
    modules,
    ctaBannerModule,
    howYouCanHelpHeading,
    reUseAHowWeCanHelpPost,
    displayAStory,
    showPartnerLogos,
  } = data.datoCmsProgram

  const pageRoot = {
    name: "What We Do",
    path: "/what-we-do",
  }
  const breadcrumbs = [
    {
      name: programTitle,
      path: location.pathname,
    },
  ]
  const renderModules = modules.filter(
    ({ __typename }) => __typename !== "DatoCmsBodyCopy"
  )

  const pageHero = modules.filter(
    ({ __typename }) => __typename === "DatoCmsHero"
  )

  const [helpPosts, setHelpPosts] = useState([])

  const howCanWeHelpPosts = modules.filter(
    ({ __typename }) => __typename === "DatoCmsHowYouCanHelp"
  )

  useEffect(() => {
    if (howCanWeHelpPosts !== null) {
      setHelpPosts(howCanWeHelpPosts)
      return
    }
  }, [])

  const [reUsed, setReUsed] = useState([])

  useEffect(() => {
    if (reUseAHowWeCanHelpPost !== null) {
      const reUseableListing = reUseAHowWeCanHelpPost.modules.filter(
        ({ __typename }) => __typename === "DatoCmsHowYouCanHelp"
      )
      setReUsed(reUseableListing)
      return
    }
  }, [])

  const handleHeroImage = () => {
    if (pageHero.length > 0) {
      return pageHero[0].backgroundImage
    } else {
      return null
    }
  }
  const handleHasImage = () => {
    if (pageHero[0].backgroundImage) {
      return true
    } else {
      return false
    }
  }

  const handleStoryHero = () => {
    const storyHero = displayAStory.modules.filter(
      ({ __typename }) => __typename === "DatoCmsHero"
    )
    if (displayAStory !== null) {
      if (storyHero.length > 0) {
        return storyHero[0].backgroundImage
      } else {
        return
      }
    }
  }

  const handleHasStoryHero = () => {
    const storyHero = displayAStory.modules.filter(
      ({ __typename }) => __typename === "DatoCmsHero"
    )
    if (displayAStory !== null) {
      if (storyHero.length > 0) {
        return true
      } else {
        return false
      }
    }
  }

  const handleStoryBodyCopy = () => {
    if (displayAStory !== null) {
      const storyBodyCopy = displayAStory.modules.filter(
        ({ __typename }) => __typename === "DatoCmsBodyCopy"
      )
      return storyBodyCopy[0].bodyCopyNode.childMarkdownRemark.excerpt
    }
  }

  const [isLocalPage, setIsLocalPage] = useState(false)
  useEffect(() => {
    if (programTitle === "In your local area") {
      setIsLocalPage(true)
    } else {
      setIsLocalPage(false)
    }
  }, [])

  const pageTitleMeta = `body__${programTitle
    .toLowerCase()
    .split(" ")
    .join("-")}`

  return (
    <>
      <Head
        title={seoTitle}
        url={`https://www.choosetap.com.au/what-we-do/${slug}`}
        description={seoDescription}
        keywords={seoKeywords}
        bodyClassname={pageTitleMeta}
      />
      <section className="pages__child">
        <HeroIntroBanner
          className="newsStoryDetail"
          title={pageHero[0].heroTitle}
          shortDescription={pageHero[0].heroText}
          sectionName={programTitle}
          themeColor="blue"
          hasImage={handleHasImage()}
          image={handleHeroImage()}
          hasBreadcrumbs={true}
        >
          <HeaderBreadcrumbs
            isProgram={true}
            root={pageRoot}
            crumbs={breadcrumbs}
          />
        </HeroIntroBanner>

        <main>
          <ContentBoundary>
            <ModuleRenderer isLandingInstance={false} modules={renderModules} />
          </ContentBoundary>

          {showPartnerLogos && isLocalPage && (
            <LogoGrid isPartnerLogos={true} />
          )}
          {displayAStory !== null && (
            <ImageTextBlock
              ctaLabel={`Read more`}
              ctaLink={`/stories-and-news/${displayAStory.slug}`}
              key={displayAStory.id}
              title={displayAStory.title}
              bodyCopy={handleStoryBodyCopy()}
              image={handleStoryHero()}
              hasImage={handleHasStoryHero()}
              alignRight={false}
            />
          )}

          {showPartnerLogos && !isLocalPage && (
            <LogoGrid isPartnerLogos={true} />
          )}

          <h2 className="typography__display--6 util__text-align--center util__margin-bottom--55 tabletDown-util__margin-top--50">
            {howYouCanHelpHeading}
          </h2>
          {helpPosts.length > 0 && (
            <ContentBoundary>
              <div className="desktop-util__margin-bottom--40">
                <div className="grids__listing">
                  {helpPosts.map((d) => {
                    const {
                      id,
                      bodyCopyNode: { childMarkdownRemark },
                      hero,
                      title,
                    } = d
                    const { excerpt, rawMarkdownBody } = childMarkdownRemark
                    return (
                      <StoryNewsListing
                        excerpt={rawMarkdownBody}
                        key={id}
                        title={title}
                        image={hero}
                        hasImage={true}
                        isOurProgramListing={true}
                        hasCta={false}
                      />
                    )
                  })}
                </div>
              </div>
            </ContentBoundary>
          )}

          {reUsed.length > 0 && (
            <ContentBoundary>
              <div className="grids__listing">
                {reUsed.map((d) => {
                  const {
                    id,
                    bodyCopyNode: { childMarkdownRemark },
                    hero,
                    title,
                  } = d
                  const { excerpt } = childMarkdownRemark
                  return (
                    <StoryNewsListing
                      excerpt={excerpt}
                      key={id}
                      title={title}
                      image={hero}
                      hasImage={true}
                      isOurProgramListing={true}
                      hasCta={false}
                    />
                  )
                })}
              </div>
            </ContentBoundary>
          )}
          {ctaBannerModule.length > 0 && (
            <CtaBanner
              isFooterInstance={true}
              title={ctaBannerModule[0].title}
              ctaLabel={ctaBannerModule[0].ctaLabel}
              ctaLink={ctaBannerModule[0].ctaLink}
              ctaPageUrl={ctaBannerModule[0].ctaPageUrl}
              image={ctaBannerModule[0].backgroundImage}
            />
          )}
        </main>
      </section>
    </>
  )
}

export default OurProgramDetailTemplate

export const query = graphql`
  query($slug: String!) {
    datoCmsProgram(slug: { eq: $slug }) {
      seoTitle
      seoKeywords
      seoDescription
      programTitle
      showPartnerLogos
      howYouCanHelpHeading
      slug
      ctaBannerModule {
        __typename
        title
        backgroundImage {
          url
        }
        ctaLink {
          __typename
          ... on DatoCmsPost {
            slug
          }
          ... on DatoCmsProgram {
            slug
          }
        }
        ctaLabel
        ctaPageUrl
      }
      modules {
        __typename
        ... on DatoCmsTwoColumnContentBlock {
          column1Title
          column1Body
          column1CtaLabel
          column1CtaLink {
            __typename
            ... on DatoCmsPost {
              slug
            }
            __typename
            ... on DatoCmsProgramPartnerChild {
              slug
            }
          }
          column1CtaPageUrl
          column2Title
          column2Body
          column2CtaLabel
          column2CtaPageUrl
          column2CtaLink {
            __typename
            ... on DatoCmsPost {
              slug
            }
            __typename
            ... on DatoCmsProgramPartnerChild {
              slug
            }
          }
        }

        ... on DatoCmsBodyCopy {
          bodyCopyNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsHero {
          id
          backgroundImage {
            alt
            url
          }
          heroTitle
          heroText
        }
        ... on DatoCmsImageTextBlock {
          id
          alignRight
          bodyCopy
          title
          image {
            url
            alt
          }
        }
        ... on DatoCmsHowYouCanHelp {
          title
          bodyCopyNode {
            childMarkdownRemark {
              html
              excerpt
              rawMarkdownBody
            }
          }
          hero {
            url
            alt
          }
        }

        ... on DatoCmsCtaBanner {
          title
          backgroundImage {
            url
            alt
          }
          ctaLink {
            ... on DatoCmsPost {
              slug
            }
            ... on DatoCmsProgram {
              slug
            }
          }
          ctaLabel
          ctaPageUrl
        }
      }
      reUseAHowWeCanHelpPost {
        modules {
          __typename
          ... on DatoCmsHowYouCanHelp {
            title
            bodyCopyNode {
              childMarkdownRemark {
                html
                rawMarkdownBody
                excerpt
              }
            }
            hero {
              url
              alt
            }
          }
        }
      }
      displayAStory {
        id
        title
        storyListing
        slug
        modules {
          __typename
          ... on DatoCmsBodyCopy {
            bodyCopyNode {
              childMarkdownRemark {
                excerpt
              }
            }
          }
          ... on DatoCmsHero {
            backgroundImage {
              url
              alt
            }
          }
        }
      }
    }
  }
`
