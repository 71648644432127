import React from "react"

interface Props {
  children: any
  className?: string
}

const ContentBoundary: React.FC<Props> = ({ children, className }) => {
  return <div className={`content_boundary ${className}`}>{children}</div>
}

export default ContentBoundary
