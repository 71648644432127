import React, { useEffect, useState } from "react"
import { graphql, StaticQuery } from "gatsby"

import Logo from "./logo"
import logoGridStyles from "../../assets/styles/modules/logo_grid.module.scss"

import ContentBoundary from "../../components/content/boundary"
import ContentBlock from "../../components/modules/content_block"

interface LogoProps {
  showLogosOnly?: boolean
  isPartnerLogos?: boolean
}

const LogoGrid = ({ showLogosOnly, isPartnerLogos }: LogoProps) => (
  <StaticQuery
    query={graphql`
      query partnerLogos {
        allDatoCmsOurProgram {
          edges {
            node {
              modules {
                __typename
                ... on DatoCmsContentBlock {
                  bodyCopy
                  title
                  ctaLabel
                  ctaPageUrl
                  ctaLink {
                    ... on DatoCmsPost {
                      slug
                    }
                  }
                }
              }
            }
          }
        }

        allDatoCmsLogoGrid(sort: { fields: position }) {
          edges {
            node {
              module {
                id
                url
                image {
                  url
                  alt
                  title
                  path
                }
              }
            }
          }
        }

        allDatoCmsSupportersLogoGrid(sort: { fields: position }) {
          edges {
            node {
              module {
                id
                url
                image {
                  url
                  alt
                  title
                  path
                }
              }
            }
          }
        }
      }
    `}
    // tslint:disable-next-line: jsx-no-lambda
    render={(data) => {
      const nationalNetwork = data.allDatoCmsOurProgram.edges[0].node.modules.filter(
        ({ __typename }) => __typename === "DatoCmsContentBlock"
      )
      const handleCtaLink = () => {
        if (nationalNetwork[0].ctaPageUrl && !nationalNetwork[0].ctaLink) {
          return nationalNetwork[0].ctaPageUrl
        } else if (
          !nationalNetwork[0].ctaPageUrl &&
          nationalNetwork[0].ctaLink
        ) {
          return nationalNetwork[0].ctaLink
        } else {
          return null
        }
      }

      const partnerLogos = data.allDatoCmsLogoGrid.edges
      const supporterLogos = data.allDatoCmsSupportersLogoGrid.edges

      const [logoType, setLogoType] = useState(partnerLogos)

      useEffect(() => {
        if (isPartnerLogos) {
          setLogoType(partnerLogos)
        } else {
          setLogoType(supporterLogos)
        }
      }, [])

      const facts = [
        {
          title: partnerLogos.length,
          description: "members",
        },
        {
          title: "04",
          description: "states",
        },
      ]

      return (
        <ContentBoundary>
          {!showLogosOnly && (
            <section className="our-national-network">
              <div className="grids__listing grids__two-column  util__grid-align--center util__padding-bottom--0 ">
                <div className="grids__two-column--col">
                  <ContentBlock
                    className="util__margin-bottom--0 util__padding-bottom--0"
                    titleClassName="typography__display--7"
                    title={nationalNetwork[0].title}
                    bodyCopy={nationalNetwork[0].bodyCopy}
                    ctaLabel={nationalNetwork[0].ctaLabel}
                    ctaLink={handleCtaLink()}
                  />
                </div>
                <div className="grids__two-column--col">
                  <div className="grids__national-network-facts">
                    {facts.map(({ title, description }) => {
                      return (
                        <div key={title} className="fact-circle">
                          <div>
                            <h3 className="typography__color--blue typography__display--5">
                              {title}
                            </h3>
                            <h3 className="typography__color--blue typography__display--3 typography__ff--flamaMedium typography__text-trans--none">
                              {description}
                            </h3>
                          </div>
                        </div>
                      )
                    })}
                    <div className="fact-circle">
                      <div>
                        <h3 className="typography__color--blue typography__display--3 typography__ff--flamaMedium typography__text-trans--none">
                          since
                        </h3>

                        <h3 className="typography__color--blue typography__display--5">
                          2011
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="our-national-network__background" />
            </section>
          )}
          <div className={logoGridStyles.logoGrid}>
            {logoType.map(({ node }) => {
              const { image, id, url } = node.module[0]

              return (
                <Logo
                  image={image.url}
                  key={id}
                  link={url}
                  altText={image.alt}
                />
              )
            })}
          </div>
        </ContentBoundary>
      )
    }}
  />
)

export default LogoGrid
