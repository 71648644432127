import React from "react"
import { Link } from "gatsby"
import styles from "./index.module.scss"
import ContentBoundary from "../content/boundary"

interface HeaderBreadcrumbsProps {
  crumbs: [
    {
      name: string
      path: string
    }
  ]
  isProgram?: boolean
  root: {
    name: string
    path: string
  }
}

const HeaderBreadcrumbs = ({ isProgram, crumbs, root }) => {
  // console.log("crumbs", crumbs)
  // console.log("root", root)

  // const customCrumbLabel = crumbs.toLowerCase().replace("-", " ")

  return (
    <nav id="breadcrumbs-nav" className={styles.breadcrumbs}>
      {/* <ContentBoundary> */}
      <ul className={styles.ul}>
        <li
          className={`${styles.li} ${
            !isProgram
              ? "typography__color--navy  util__opacity--5"
              : "typography__color--white"
          }
            typography__text-trans--cap
           `}
        >
          <Link
            to={root.path}
            className={`${
              !isProgram
                ? "typography__color--navy"
                : "typography__color--white"
            }`}
          >
            {root.name}
          </Link>
        </li>
        {crumbs.length > 0 &&
          crumbs.map((crumb) => (
            <>
              <li
                className={`${styles.li} ${
                  !isProgram
                    ? "typography__color--navy   util__opacity--5"
                    : "typography__color--white"
                }
                    typography__text-trans--cap`}
              >
                <Link
                  to={crumb.path}
                  className={`${
                    !isProgram
                      ? "typography__color--navy"
                      : "typography__color--white"
                  }`}
                >
                  {crumb.name}
                </Link>
              </li>
            </>
          ))}
      </ul>
      {/* </ContentBoundary> */}
    </nav>
  )
}

export default HeaderBreadcrumbs
